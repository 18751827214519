<template>
  <div id="mediumCheck">
    <el-dialog
      :title="mediumCheckFormTitle"
      width="1200px"
      :visible.sync="mediumCheckDialogVisible"
      :close-on-click-modal="false"
      @close="mediumCheckDialogClose"
    >
      <el-form
        ref="mediumCheckFormRef"
        :model="mediumCheckForm"
        :rules="mediumCheckFormRules"
        label-position="right"
        label-width="130px"
      >
        <el-divider content-position="left">
          阳性菌检验室运行情况
        </el-divider>
        <el-row>
          <el-col :span="6">
            <el-form-item label="运行时间" prop="runtime">
              <el-date-picker v-model="mediumCheckForm.runtime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自净时间" prop="selfTime">
              <el-date-picker v-model="mediumCheckForm.selfTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="温度" prop="temperature">
              <el-input v-model="mediumCheckForm.temperature" placeholder="请输入温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="湿度" prop="humidity">
              <el-input v-model="mediumCheckForm.humidity" placeholder="请输入湿度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="臭氧消毒时间" prop="disinfectionTime">
              <el-date-picker v-model="mediumCheckForm.disinfectionTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="压差" prop="pressure">
              <el-input v-model="mediumCheckForm.pressure" placeholder="请输入压差" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="区域" prop="area">
              <el-select v-model="mediumCheckForm.area" placeholder="请选择区域">
                <el-option key="1" label="洁净区" :value="1" />
                <el-option key="2" label="非洁净区" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数量级" prop="magnitude">
              <el-select v-model="mediumCheckForm.magnitude" placeholder="请选择数量级">
                <el-option key="1" label="1万级" :value="1" />
                <el-option key="2" label="10万级" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">
          计数培养基
        </el-divider>
        <el-row>
          <el-col :span="5">
            <el-form-item label="待检查培养基名称" prop="checkedMedium">
              <el-input v-model="mediumCheckForm.checkedMedium" placeholder="请输入待检查培养基名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="待检查数量" prop="checkedQuantity">
              <el-input v-model="mediumCheckForm.checkedQuantity" placeholder="请输入待检查数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="对照培养基名称" prop="contrastMedium">
              <el-input v-model="mediumCheckForm.contrastMedium" placeholder="请输入对照培养基名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="对照数量" prop="contrastQuantity">
              <el-input v-model="mediumCheckForm.contrastQuantity" placeholder="请输入对照数量" clearable />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="5">
            <el-form-item label="培养基批号" prop="checkedMediumBatchNo">
              <el-input v-model="mediumCheckForm.checkedMediumBatchNo" placeholder="请输入培养基批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="购入日期" prop="checkedPurchaseDate">
              <el-date-picker v-model="mediumCheckForm.checkedPurchaseDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="培养基批号" prop="contrastMediumBatchNo">
              <el-input v-model="mediumCheckForm.contrastMediumBatchNo" placeholder="请输入培养基批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="购入日期" prop="contrastPurchaseDate">
              <el-date-picker v-model="mediumCheckForm.contrastPurchaseDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="5">
            <el-form-item label="生产厂家" prop="checkedManufacturer">
              <el-input v-model="mediumCheckForm.checkedManufacturer" placeholder="请输入生产厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="灭菌时间" prop="checkedSterilizationTime">
              <el-date-picker
                v-model="mediumCheckForm.checkedSterilizationTime"
                type="datetime"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="生产厂家" prop="contrastManufacturer">
              <el-input v-model="mediumCheckForm.contrastManufacturer" placeholder="请输入培养基批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="灭菌时间" prop="contrastSterilizationTime">
              <el-date-picker
                v-model="mediumCheckForm.contrastSterilizationTime"
                type="datetime"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="5">
            <el-form-item label="灭菌温度" prop="checkedTemperature">
              <el-input v-model="mediumCheckForm.checkedTemperature" placeholder="请输入培养时间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="培养箱编号" prop="checkedIncubatorNo">
              <el-input v-model="mediumCheckForm.checkedIncubatorNo" placeholder="请输入培养温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="灭菌温度" prop="contrastTemperature">
              <el-input v-model="mediumCheckForm.contrastTemperature" placeholder="请输入灭菌温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="培养箱编号" prop="contrastIncubatorNo">
              <el-input v-model="mediumCheckForm.contrastIncubatorNo" placeholder="请输入培养箱编号" clearable />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="5">
            <el-form-item label="培养时间" prop="checkedTrainingTime">
              <el-input v-model="mediumCheckForm.checkedTrainingTime" placeholder="请输入培养时间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="培养温度" prop="checkedCultureTemperature">
              <el-input v-model="mediumCheckForm.checkedCultureTemperature" placeholder="请输入培养温度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="培养时间" prop="contrastTrainingTime">
              <el-input v-model="mediumCheckForm.contrastTrainingTime" placeholder="请输入培养时间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="培养温度" prop="contrastCultureTemperature">
              <el-input v-model="mediumCheckForm.contrastCultureTemperature" placeholder="请输入培养温度" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <vxe-toolbar v-if="mediumCheckFormTitle !== '计数用培养基适用性检查记录详情'">
          <template #buttons>
            <vxe-button @click="insertRowEvent()">
              新增
            </vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="xTable"
          border
          resizable
          show-overflow
          keep-source
          :data="checkDetailList"
          :edit-config="{trigger: 'click', mode: 'cell'}"
        >
          <vxe-table-column
            field="strainName"
            title="菌种名称"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />
          <vxe-table-colgroup title="待检查培养基">
            <vxe-table-column
              field="checkedPlate1"
              title="平皿1"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="checkedPlate2"
              title="平皿2"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="checkedAverage"
              title="平均数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="checkedNegative"
              title="阴性对照"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-colgroup title="对照培养基">
            <vxe-table-column
              field="contrastPlate1"
              title="平皿1"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="contrastPlate2"
              title="平皿2"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="contrastAverage"
              title="平均数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="contrastNegative"
              title="阴性对照"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
          </vxe-table-colgroup>
          <vxe-table-column
            field="averageDivide"
            title="平均数R1/平均数R2"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />
          <vxe-table-column
            field="verificationConclusion"
            title="验证结论"
            :edit-render="{name: '$input', props: {clearable: true}}"
          />

          <vxe-table-column v-if="mediumCheckFormTitle !== '计数用培养基适用性检查记录详情'" title="操作" width="100">
            <template #default="{ row }">
              <template>
                <vxe-button @click="removeRowEvent(row)">
                  删除
                </vxe-button>
              </template>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-row>
          <el-col :span="24">
            <el-form-item label="" prop="conclusion">
              <el-input
                v-model="conclusion"
                type="textarea"
                :rows="3"
                readonly
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验人员" prop="inspector">
              <el-input v-model="mediumCheckForm.inspector" placeholder="请输入检验人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验时间" prop="testTime">
              <el-date-picker v-model="mediumCheckForm.testTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核人员" prop="reviewer">
              <el-input v-model="mediumCheckForm.reviewer" placeholder="请输入复核人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核时间" prop="reviewTime">
              <el-date-picker v-model="mediumCheckForm.reviewTime" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="mediumCheckDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="mediumCheckFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="mediumCheckPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="runtime" label="运行时间" />
      <el-table-column prop="selfTime" label="自净时间" />
      <el-table-column prop="temperature" label="温度" />
      <el-table-column prop="humidity" label="湿度" />
      <el-table-column prop="disinfectionTime" label="臭氧消毒时间" />
      <el-table-column prop="pressure" label="压差" />
      <el-table-column prop="area" label="区域">
        <template slot-scope="scope">
          <span v-if="scope.row.area == 1">洁净区</span>
          <span v-if="scope.row.area == 2">非洁净区</span>
        </template>
      </el-table-column>
      <el-table-column prop="magnitude" label="数量级">
        <template slot-scope="scope">
          <span v-if="scope.row.magnitude == 1">1万级</span>
          <span v-if="scope.row.magnitude == 2">10万级</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkedMedium" label="待检查培养基名称" />
      <el-table-column prop="checkedQuantity" label="待检查数量" />
      <el-table-column prop="checkedMediumBatchNo" label="待检查培养基批号" />
      <el-table-column prop="checkedPurchaseDate" label="待检查购入日期" />
      <el-table-column prop="checkedManufacturer" label="待检查生产厂家" />
      <el-table-column prop="checkedSterilizationTime" label="待检查灭菌时间" />
      <el-table-column prop="checkedTemperature" label="待检查灭菌温度" />
      <el-table-column prop="checkedIncubatorNo" label="待检查培养箱编号" />
      <el-table-column prop="checkedTrainingTime" label="待检查培养时间" />
      <el-table-column prop="checkedCultureTemperature" label="待检查培养温度" />
      <el-table-column prop="contrastMedium" label="对照培养基名称" />
      <el-table-column prop="contrastQuantity" label="对照数量" />
      <el-table-column prop="contrastMediumBatchNo" label="对照培养基批号" />
      <el-table-column prop="contrastPurchaseDate" label="对照购入日期" />
      <el-table-column prop="contrastManufacturer" label="对照生产厂家" />
      <el-table-column prop="contrastSterilizationTime" label="对照灭菌时间" />
      <el-table-column prop="contrastTemperature" label="对照灭菌温度" />
      <el-table-column prop="contrastIncubatorNo" label="对照培养箱编号" />
      <el-table-column prop="contrastTrainingTime" label="对照培养时间" />
      <el-table-column prop="contrastCultureTemperature" label="对照培养温度" />
      <el-table-column prop="conclusion" label="结论" />
      <el-table-column prop="inspector" label="检验人员" />
      <el-table-column prop="testTime" label="检验时间" />
      <el-table-column prop="reviewer" label="复核人员" />
      <el-table-column prop="reviewTime" label="复核时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="mediumCheckPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMediumCheck, deleteMediumCheck, updateMediumCheck, selectMediumCheckInfo, selectMediumCheckList } from '@/api/quality/mediumCheck'

export default {
  data () {
    return {
      mediumCheckDialogVisible: false,
      mediumCheckFormTitle: '',
      mediumCheckForm: {
        id: '',
        runtime: '',
        selfTime: '',
        temperature: '',
        humidity: '',
        disinfectionTime: '',
        pressure: '',
        area: '',
        magnitude: '',
        checkedMedium: '',
        checkedQuantity: '',
        checkedMediumBatchNo: '',
        checkedPurchaseDate: '',
        checkedManufacturer: '',
        checkedSterilizationTime: '',
        checkedTemperature: '',
        checkedIncubatorNo: '',
        checkedTrainingTime: '',
        checkedCultureTemperature: '',
        contrastMedium: '',
        contrastQuantity: '',
        contrastMediumBatchNo: '',
        contrastPurchaseDate: '',
        contrastManufacturer: '',
        contrastSterilizationTime: '',
        contrastTemperature: '',
        contrastIncubatorNo: '',
        contrastTrainingTime: '',
        contrastCultureTemperature: '',
        conclusion: '',
        inspector: '',
        testTime: '',
        reviewer: '',
        reviewTime: '',
        checkDetailJson: ''
      },
      mediumCheckFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      mediumCheckPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      checkDetailList: [],
      conclusion: '结论：以上计数用培养基适用性验证依据《培养基适用性检查操作规程》进行检验，被检培养基上的菌落平均数与对照培养基上的菌落平均数的比值范围在之间，且菌落形态大小与对照培养基上的菌落，该培养基的适用性检查要求。'
    }
  },
  created () {
    selectMediumCheckList(this.searchForm).then(res => {
      this.mediumCheckPage = res
    })
  },
  methods: {
    mediumCheckDialogClose () {
      this.$refs.mediumCheckFormRef.resetFields()
      this.checkDetailList = []
      this.mediumCheckForm.id = ''
    },
    mediumCheckFormSubmit () {
      if (this.mediumCheckFormTitle === '计数用培养基适用性检查记录详情') {
        this.mediumCheckDialogVisible = false
        return
      }
      this.$refs.mediumCheckFormRef.validate(async valid => {
        if (valid) {
          this.mediumCheckForm.checkDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.mediumCheckFormTitle === '新增计数用培养基适用性检查记录') {
            await addMediumCheck(this.mediumCheckForm)
          } else if (this.mediumCheckFormTitle === '修改计数用培养基适用性检查记录') {
            await updateMediumCheck(this.mediumCheckForm)
          }
          this.mediumCheckPage = await selectMediumCheckList(this.searchForm)
          this.mediumCheckDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.mediumCheckFormTitle = '新增计数用培养基适用性检查记录'
      this.mediumCheckDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMediumCheck(row.id)
        if (this.mediumCheckPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.mediumCheckPage = await selectMediumCheckList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.mediumCheckFormTitle = '修改计数用培养基适用性检查记录'
      this.mediumCheckDialogVisible = true
      this.selectMediumCheckInfoById(row.id)
    },
    handleInfo (index, row) {
      this.mediumCheckFormTitle = '计数用培养基适用性检查记录详情'
      this.mediumCheckDialogVisible = true
      this.selectMediumCheckInfoById(row.id)
    },
    selectMediumCheckInfoById (id) {
      selectMediumCheckInfo(id).then(res => {
        this.mediumCheckForm.id = res.id
        this.mediumCheckForm.runtime = res.runtime
        this.mediumCheckForm.selfTime = res.selfTime
        this.mediumCheckForm.temperature = res.temperature
        this.mediumCheckForm.humidity = res.humidity
        this.mediumCheckForm.disinfectionTime = res.disinfectionTime
        this.mediumCheckForm.pressure = res.pressure
        this.mediumCheckForm.area = res.area
        this.mediumCheckForm.magnitude = res.magnitude
        this.mediumCheckForm.checkedMedium = res.checkedMedium
        this.mediumCheckForm.checkedQuantity = res.checkedQuantity
        this.mediumCheckForm.checkedMediumBatchNo = res.checkedMediumBatchNo
        this.mediumCheckForm.checkedPurchaseDate = res.checkedPurchaseDate
        this.mediumCheckForm.checkedManufacturer = res.checkedManufacturer
        this.mediumCheckForm.checkedSterilizationTime = res.checkedSterilizationTime
        this.mediumCheckForm.checkedTemperature = res.checkedTemperature
        this.mediumCheckForm.checkedIncubatorNo = res.checkedIncubatorNo
        this.mediumCheckForm.checkedTrainingTime = res.checkedTrainingTime
        this.mediumCheckForm.checkedCultureTemperature = res.checkedCultureTemperature
        this.mediumCheckForm.contrastQuantity = res.contrastQuantity
        this.mediumCheckForm.contrastMediumBatchNo = res.contrastMediumBatchNo
        this.mediumCheckForm.contrastPurchaseDate = res.contrastPurchaseDate
        this.mediumCheckForm.contrastManufacturer = res.contrastManufacturer
        this.mediumCheckForm.contrastSterilizationTime = res.contrastSterilizationTime
        this.mediumCheckForm.contrastTemperature = res.contrastTemperature
        this.mediumCheckForm.contrastIncubatorNo = res.contrastIncubatorNo
        this.mediumCheckForm.contrastTrainingTime = res.contrastTrainingTime
        this.mediumCheckForm.contrastCultureTemperature = res.contrastCultureTemperature
        this.mediumCheckForm.conclusion = res.conclusion
        this.mediumCheckForm.inspector = res.inspector
        this.mediumCheckForm.testTime = res.testTime
        this.mediumCheckForm.reviewer = res.reviewer
        this.mediumCheckForm.reviewTime = res.reviewTime
        this.checkDetailList = res.checkDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMediumCheckList(this.searchForm).then(res => {
        this.mediumCheckPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMediumCheckList(this.searchForm).then(res => {
        this.mediumCheckPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMediumCheckList(this.searchForm).then(res => {
        this.mediumCheckPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
